export class City{
    id : number;
    idDep : number;
    codigo: string;
    nombre : string;
    estado : boolean;

    constructor(id: number) {
        this.id = id;
    }
}