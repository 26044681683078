import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
})
export class SidebarComponent implements OnInit {
  collapseShow = "hidden";
  admin: Number;
  constructor( private router: Router) {}

  ngOnInit() {
    this.admin = Number(sessionStorage.getItem('idSession'));
  }
  toggleCollapseShow(classes) {
    this.collapseShow = classes;
  }
  cerrarsesion(){
    sessionStorage.clear();
    this.router.navigate(['']);
  }
}
